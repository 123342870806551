<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form inline>
        <el-form-item>
          <el-input v-model="searchForm.nickname" placeholder="输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.phone" placeholder="输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="用户编号">
          <el-input-number v-model="searchForm.uid"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.mode">
            <el-option :value="0" label="全部项目"></el-option>
            <el-option v-for="(item,key) in PartnershipActiveMode" :key="key" :value="item.value"
                       :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.status">
            <el-option :value="0" label="状态"></el-option>
            <el-option v-for="(item,key) in PartnershipActiveStatus" :key="key" :value="item.value"
                       :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="flex-def flex-cCenter">
            <el-button @click="searchGo" type="primary">搜索</el-button>
            <el-button @click="searchReset">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column label="参与人员" show-overflow-tooltip>
          <template #default="s">
            <div class="flex-def">
              <img :src="s.row.user.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
              <div style="margin-left: 15px">
                <div>{{s.row.user.nickname}}({{s.row.user.id}})</div>
                <div>{{s.row.user.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="项目名称" show-overflow-tooltip>
          <template #default="s">
            {{EnumValue2Label(s.row.mode,PartnershipActiveMode)}}
          </template>
        </el-table-column>
        <el-table-column prop="target" label="目标量" show-overflow-tooltip></el-table-column>
        <el-table-column prop="catch" label="达成量" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" show-overflow-tooltip>
          <template #default="s">
            {{EnumValue2Label(s.row.status,PartnershipActiveStatus)}}
          </template>
        </el-table-column>
        <el-table-column label="完成明细" show-overflow-tooltip>
          <template #default="s">
            <active-log-item-search :log-i-d="s.row.id">
              <el-button type="success">查看明细</el-button>
            </active-log-item-search>
          </template>
        </el-table-column>
        <el-table-column label="奖励" show-overflow-tooltip>
          <template #default="s">
            <div>{{s.row.award}}</div>
            <div v-if="s.row.status === PartnershipActiveStatus.grant.value" class="y-desc">
              发放时间：{{s.row.award_grant_time | date}}
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {PartnershipActiveMode, PartnershipActiveStatus} from "@/common/mod/plugin/partnership/active";
import {EnumValue2Label} from "../../../../common/util";
import ActiveLogItemSearch from "@/components/shop/plugin/partnership/activeLogItemSearch.vue";

const searchFormTpl = {
  nickname: "",
  phone: "",
  uid: 0,
  mode: 0,
  status: 0,
}

export default {
  name: "manage",
  components: {ActiveLogItemSearch},
  computed: {
    PartnershipActiveStatus() {
      return PartnershipActiveStatus
    },
    PartnershipActiveMode() {
      return PartnershipActiveMode
    }
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      range: [],
      useID: 0,
      searchForm: {...searchFormTpl}
    }
  },
  mounted() {
    this.useID = parseInt(this.$route.query.id) || 0;
    if (!this.useID) {
      this.$message.error("异常访问");
      this.$router.back();
      return
    }
    this.load();
  },
  methods: {
    EnumValue2Label,
    searchReset() {
      this.searchForm = {...searchFormTpl};
      this.searchGo();
    },
    searchGo() {
      this.page = 1;
      this.list.length = 0;
      this.load();
    },

    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.partnership.activeLogSearch({
        active_id: this.useID,
        page: this.page,
        ...this.searchForm,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>
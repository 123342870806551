export const PartnershipActiveMode = {
    sysAgent:{
        label:"系统代理邀请",
        value:1
    },
    shopowner:{
        label:"店长邀请",
        value:2
    },
    partner:{
        label:"合伙人邀请",
        value:3
    },
    goods:{
        label:"商品推广",
        value:4
    },
}

export const PartnershipActiveStatus = {
    wait:{
        label:"未完成",
        value:1
    },
    suc:{
        label:"已完成",
        value:2
    },
    grant:{
        label:"奖励已发放",
        value:3
    },
    lost:{
        label:"过期",
        value:4
    },
}

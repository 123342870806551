<template>
  <div>
    <div @click="open">
      <slot></slot>
    </div>
    <el-dialog title="任务明细" :visible.sync="show" destroy-on-close>
      <el-table :data="list">
        <el-table-column prop="id" label="记录编号"></el-table-column>
        <el-table-column prop="target_id" label="目标编号（用户|订单）"></el-table-column>
        <el-table-column prop="log" label="时间">
          <template v-slot="s">
            {{ s.row.created_at | date }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "activeLogItemSearch",
  props: {
    logID: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      show: false,
      range: [],
      list: [],
      page: 1,
      total: 0,
    }
  },
  methods: {
    searchReset(){
      this.range = [];
      this.search();
    },
    search(){
      this.award_log_list = [];
      this.award_log_total = 0;
      this.pageChange(1);
    },
    pageChange(e) {
      this.page = e;
      this.open();
    },
    open() {
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.shop.plugin.partnership.activeLogItemSearch({
        page: this.page,
        log_id: this.logID,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
        this.show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    }
  }
}
</script>

<style scoped>

</style>